<template>
  <RouterView />
</template>

<script>
export default {
  beforeMount () {
    this.selectGpsDevice(this.$route.params.imei)
  },

  beforeRouteUpdate (to, from, next) {
    // If user change gps device while the GPS view is visible
    // we reload all data
    this.selectGpsDevice(to.params.imei)

    next()
  },

  methods: {
    async selectGpsDevice (imei) {
      this.$store.commit('gps/select', imei)
      this.$store.commit('huntarea/select', null)
    }
  }
}
</script>
